import React from "react";
import { css } from "@emotion/core";

import Layout from "../components/layout";

import { TitleHeader, wrapper } from "../components/defaults/styles/elements"
import Info from "../components/defaults/assets/svg/info.svg"
import PressFooter from "../components/pressfooter"
import { color } from "../components/defaults/styles/theme"

const seo = {
  frontmatter: {
    title: "Imprint",
  },
}

const Partner = () => {
    return (
      <>
        <Layout seo={seo}>
          <TitleHeader
            title="Imprint"
            subtitle="<p>Last updated: 2020-08-13T16:50+02 UTC</p>"
            icon={<Info />}
          />

          <div
            css={[
              css`
                background: ${color.main_light};
                padding: 4rem 0;
                margin-top: 2em;
              `,
            ]}
          >
            <section
              css={[
                wrapper,
                css`
                  /* max-width: 48rem; */
                  margin-bottom: 5em;
                  p {
                    margin-bottom: 0;
                    font-size: 0.95em;
                    padding-bottom: 0.5em;
                  }
                  > div {
                    margin: 1.5em 0;
                  }
                `,
              ]}
            >
              <p>Plug & Play Germany GmbH</p>
              <p>
                Pfaffenwaldring 19
                <br />
                70569 Stuttgart
                <br />
                Germany
              </p>

              <div>
                <p>
                  <b>Management</b>
                </p>
                <p>
                  Sascha Karimpour <br /> Managing Director Plug & Play Germany
                  GmbH
                </p>
              </div>

              <div>
                <p>
                  <b>District Court/commercial Register:</b>
                </p>
                <p>
                  German Commercial Register (HRA) entry no:
                  <br /> 769582 Local Court of Stuttgart
                </p>
              </div>

              <div>
                <p>
                  <b>Responsibility for content:</b>
                </p>
                <p>
                  Sascha Karimpour (Plug & Play Germany GmbH)
                  <br />
                  Pfaffenwaldring 19
                  <br />
                  70569 Stuttgart
                  <br />
                  Germany
                </p>
              </div>

              <div>
                <p>
                  <b>Contact</b>
                </p>
                <p>
                  Sascha Karimpour
                  <br />
                  Email: sascha@pnptc.com
                </p>
              </div>
            </section>
          </div>

          <PressFooter theme={color.main_light} />
        </Layout>
      </>
    )
}

export default Partner;